           

.scroll-new{
    max-height: 80vh;
    overflow-y: scroll;
    cursor: pointer;
} 

.progress{
    height: 17px !important;
}

/* aktif eğitim içeriğini göstermek için yazıldı*/
.active-video-style{
    margin-bottom: 1rem;
    margin-right: 1rem;
    padding: 0.7rem 0 0.7rem 0.7rem;
    /* background-color: red; */
    border: none;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    background-color: #fff;
    background-clip: border-box;
    width: 100%;
}

.text-cut{
    display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
   